<template>
  <div class="flex flex-col gap-y-4">
    <section id="motif" class="flex flex-col gap-y-2">
      <h1 class="font-EffraM text-base text-gray-700 flex items-center gap-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
        >
          <g clip-path="url(#clip0)">
            <path
              stroke="#767676"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.25 5.25h.008m10.184 4.807l-5.377 5.378c-.14.14-.305.25-.487.326-.182.075-.377.114-.574.114-.197 0-.393-.039-.575-.114-.182-.076-.347-.186-.487-.326L1.5 9V1.5H9l6.443 6.442c.279.282.436.662.436 1.058s-.157.776-.436 1.057z"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#fff" d="M0 0H18V18H0z" />
            </clipPath>
          </defs>
        </svg>
        {{ $t("patient.motif") }}
      </h1>
      <div class="relative">
        <select
          class="
            w-full
            bg-transparent
            py-4
            rounded
            px-1
            appearance-none
            font-EffraM
            text-lg
          "
          style="border: solid 2px #e4e4e4; color: #767676"
          v-model="forms.motif"
        >
          <option
            :value="null"
            disabled="disabled"
            selected="selected"
            class="text-gray-600"
          >
            {{ $t("patient.select_motif") }}
          </option>
          <option
            v-for="(tarif, index) in tarifs"
            :key="index"
            :value="tarif._id"
          >
            {{ tarif.title }}
          </option>
        </select>
        <span
          class="
            text-gray-600
            absolute
            right-0
            top-0
            h-full
            flex
            items-center
            justify-center
            px-2
          "
        >
          <i class="fas fa-chevron-down"></i>
        </span>
      </div>
    </section>

    <div class="flex flex-col gap-y-4 relative">
      <!-- If Not Choice Motif -->
      <div
        v-if="!forms.motif"
        class="
          bg-white bg-opacity-40
          absolute
          inset-0
          flex
          items-center
          justify-center
        "
      >
        <button
          class="
            bg-rosDok-ultra
            flex
            items-center
            gap-x-2
            text-white
            font-EffraM
            text-lg
            py-4
            px-8
            rounded
            shadow-lg
            cursor-not-allowed
            uppercase
            border-0
          "
          :title="$t('patient.choices_motif')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            fill="none"
            viewBox="0 0 19 18"
          >
            <g clip-path="url(#clip0)">
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5.75 5.25h.008m10.184 4.807l-5.377 5.378c-.14.14-.305.25-.487.326-.182.075-.377.114-.574.114-.197 0-.393-.039-.575-.114-.182-.076-.347-.186-.486-.326L2 9V1.5h7.5l6.443 6.442c.279.282.436.662.436 1.058s-.157.776-.437 1.057z"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <path fill="#fff" d="M0 0H18V18H0z" transform="translate(.5)" />
              </clipPath>
            </defs>
          </svg>

          {{ $t("patient.choices_motif") }}
        </button>
      </div>
      <!-- If Choice Motif -->
      <header
        class="flex justify-between items-center gap-x-2"
        v-if="availibiles.days.length"
      >
        <div id="leftChev" class="cursor-pointer" @click="slideDays('left')">
          <i class="fas fa-chevron-left text-black text-2xl"></i>
        </div>
        <div
          id="centerSilde"
          class="
            flex-1 flex flex-nowrap
            gap-x-5
            overflow-x-scroll
            scrolling-touch
            availabel-hours-scroll-color
            pb-4
          "
        >
          <div
            v-for="(day, index) in availibiles.days"
            :key="index"
            class="
              flex flex-col
              gap-y-1
              items-center
              cursor-pointer
              pb-2
              border-0 border-b-2 border-solid
              flex-shrink-0
              w-auto
              lg:w-135
              hover:border-rosDok-ultra
            "
            :class="
              _moment()(forms.day).format('DD/MM') ===
              _moment()().day(day.day).format('DD/MM')
                ? 'border-rosDok-ultra'
                : 'border-gray-600'
            "
            @click="genreationHours(day.day)"
          >
            <h1
              class="text-lg text-black font-EffraM"
              v-html="nameDay(day.day)"
            ></h1>
            <p class="text-base text-gray-600 font-EffraR">
              {{ $t("patient.aucune_disponibilité") }}
            </p>
          </div>
        </div>
        <div id="rightChev" class="cursor-pointer" @click="slideDays('right')">
          <i class="fas fa-chevron-right text-black text-2xl"></i>
        </div>
      </header>
      <template v-if="!loaderHours">
        <section
          id="bodyAvailabelSlots"
          class="flex flex-col gap-y-4"
          v-if="
            availibiles.hours.afternoon.length ||
            availibiles.hours.morning.length
          "
        >
          <div class="flex flex-col gap-y-1">
            <p class="flex gap-x-2 items-center">
              <span class="font-EffraM text-black text-lg">
                {{ $t("patient.matin") }}
              </span>
              <span class="text-gray-500 font-EffraR text-base"
                >({{ availibiles.hours.morning.length }}
                {{ $t("patient.disponibilites") }})</span
              >
            </p>
            <div
              class="grid gap-x-4 gap-y-1 grid-cols-3 lg:grid-cols-4"
              v-if="availibiles.hours.morning.length"
            >
              <span
                v-for="(h, i) in availibiles.hours.morning"
                :key="i"
                class="
                  text-lg
                  font-EffraR
                  flex
                  items-center
                  justify-center
                  rounded-lg
                "
                :class="
                  h.isAvailable
                    ? `text-black cursor-pointer ${
                        _moment()(forms.hour).format('H:mm') === h.formatedHour
                          ? 'border-rosDok-ultra text-rosDok-ultra'
                          : ''
                      }`
                    : `text-gray-500 cursor-not-allowed`
                "
                style="height: 32px; border: solid 1px #e4e4e4"
                @click="onClickHours(h)"
                >{{ h.formatedHour }}
              </span>
            </div>
            <div
              class="flex flex-col gap-y-2 items-center justify-center"
              v-else
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="83"
                height="82"
                fill="none"
                viewBox="0 0 83 82"
              >
                <path
                  stroke="#DADADA"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="10"
                  d="M65.808 13.667H17.974c-3.774 0-6.833 3.06-6.833 6.833v47.834c0 3.773 3.059 6.833 6.833 6.833h47.834c3.773 0 6.833-3.06 6.833-6.833V20.5c0-3.774-3.06-6.833-6.834-6.833zM55.557 6.833V20.5M28.225 6.833V20.5M11.14 34.167h61.5"
                />
              </svg>

              <span style="color: #585858" class="text-base font-EffraR">
                {{ $t("patient.pas_de_creneau") }}
              </span>
            </div>
          </div>

          <div class="flex flex-col gap-y-1">
            <p class="flex gap-x-2 items-center">
              <span class="font-EffraM text-black text-lg">{{
                $t("patient.apres_midi")
              }}</span>
              <span class="text-gray-500 font-EffraR text-base"
                >({{ availibiles.hours.afternoon.length }}
                {{ $t("patient.disponibilites") }})</span
              >
            </p>
            <div
              class="grid gap-x-4 gap-y-1 grid-cols-3 lg:grid-cols-4"
              v-if="availibiles.hours.afternoon.length"
            >
              <span
                v-for="(h, i) in availibiles.hours.afternoon"
                :key="i"
                class="
                  text-black text-lg
                  font-EffraR
                  flex
                  items-center
                  justify-center
                  rounded-lg
                  cursor-pointer
                "
                :class="
                  h.isAvailable
                    ? `text-black cursor-pointer ${
                        _moment()(forms.hour).format('H:mm') === h.formatedHour
                          ? 'border-rosDok-ultra text-rosDok-ultra'
                          : ''
                      }`
                    : 'text-gray-500 cursor-not-allowed'
                "
                style="height: 32px; border: solid 1px #e4e4e4"
                @click="onClickHours(h)"
                >{{ h.formatedHour }}</span
              >
            </div>
            <div
              class="flex flex-col gap-y-2 items-center justify-center"
              v-else
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="83"
                height="82"
                fill="none"
                viewBox="0 0 83 82"
              >
                <path
                  stroke="#DADADA"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="10"
                  d="M65.808 13.667H17.974c-3.774 0-6.833 3.06-6.833 6.833v47.834c0 3.773 3.059 6.833 6.833 6.833h47.834c3.773 0 6.833-3.06 6.833-6.833V20.5c0-3.774-3.06-6.833-6.834-6.833zM55.557 6.833V20.5M28.225 6.833V20.5M11.14 34.167h61.5"
                />
              </svg>

              <span style="color: #585858" class="text-base font-EffraR">
                {{ $t("patient.pas_de_creneau") }}
              </span>
            </div>
          </div>

          <template
            v-if="
              forms.day &&
              forms.hour &&
              (!$auth.getUserInfo() || $auth.getUserInfo().role !== 'DOCTOR')
            "
          >
            <button
              class="
                bg-rosedok
                text-white
                font-EffraM
                text-base
                uppercase
                border-0
                rounded-cu
                px-3
                py-5
                cursor-pointer
              "
              @click="onDone"
            >
              {{ $t("patient.reserver") }}
            </button>
          </template>
        </section>
      </template>
      <template v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; display: block; shape-rendering: auto"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
          </circle>
          <circle cx="70" cy="50" fill="#2e6bff" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "availabel-hours",
  props: {
    tarifs: {
      type: Array,
      required: true,
    },
    doctorId: {
      type: String,
      required: true,
    },
    doctorInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      forms: {
        motif: null,
        day: null,
        hour: null,
      },
      availibiles: {
        days: [
          { day: 1 },
          { day: 2 },
          { day: 3 },
          { day: 4 },
          { day: 5 },
          { day: 6 },
        ],
        hours: {
          morning: [
            {
              hourDate: "2021-06-10T07:00:00.000Z",
              formatedHour: "8:00",
              isAvailable: false,
              isOld: false,
            },
            {
              hourDate: "2021-06-10T07:30:00.000Z",
              formatedHour: "8:30",
              isAvailable: false,
              isOld: false,
            },
            {
              hourDate: "2021-06-10T08:00:00.000Z",
              formatedHour: "9:00",
              isAvailable: false,
              isOld: false,
            },
            {
              hourDate: "2021-06-10T08:30:00.000Z",
              formatedHour: "9:30",
              isAvailable: false,
              isOld: false,
            },
            {
              hourDate: "2021-06-10T09:30:00.000Z",
              formatedHour: "10:30",
              isAvailable: false,
              isOld: false,
            },
            {
              hourDate: "2021-06-10T10:00:00.000Z",
              formatedHour: "11:00",
              isAvailable: false,
              isOld: false,
            },
            {
              hourDate: "2021-06-10T10:30:00.000Z",
              formatedHour: "11:30",
              isAvailable: false,
              isOld: false,
            },
          ],
          afternoon: [
            {
              hourDate: "2021-06-10T11:00:00.000Z",
              formatedHour: "12:00",
              isAvailable: false,
              isOld: false,
            },
            {
              hourDate: "2021-06-10T11:30:00.000Z",
              formatedHour: "12:30",
              isAvailable: false,
              isOld: false,
            },
            {
              hourDate: "2021-06-10T12:00:00.000Z",
              formatedHour: "13:00",
              isAvailable: false,
              isOld: false,
            },
            {
              hourDate: "2021-06-10T12:30:00.000Z",
              formatedHour: "13:30",
              isAvailable: false,
              isOld: false,
            },
          ],
        },
      },
      loaderHours: false,
    };
  },
  watch: {
    "forms.motif": function (newVal, oldVal) {
      this.genreationDays();
    },
  },
  methods: {
    ...mapActions({
      getWeekDaysAvailibility: "agenda/GET_WEEKDAYS_AVAILIBILITY",
      getAvailableSlots: "agenda/GET_AVAILABLE_SLOTS",
    }),
    _moment() {
      return moment;
    },
    slideDays(direction) {
      if (direction === "right") {
        document.getElementById("centerSilde").scrollLeft += 24;
      }

      if (direction === "left") {
        document.getElementById("centerSilde").scrollLeft += -24;
      }
    },
    nameDay(day) {
      let currectDay = moment().day(day).format("DD/MM");

      if (currectDay === moment().format("DD/MM")) {
        return `Aujourd'hui`;
      }

      if (currectDay === moment().add(1, "days").format("DD/MM")) {
        return `Demain`;
      }

      return `<span class="capitalize">${moment()
        .day(day)
        .format("ddd DD MMM")}</span>`;
    },
    async genreationDays() {
      await this.getWeekDaysAvailibility({
        id: this.doctorId,
        categoryId: this.forms.motif,
        onData: (data) => {
          data.sort(function (a, b) {
            return a.day - b.day;
          });

          let filter = data.filter(function (a) {
            return !moment().day(a.day).isBefore(new Date(), "days");
          });

          this.availibiles.days = filter;

          if (filter[0].day) {
            // Generated Hours today
            this.genreationHours(filter[0].day);
          }
        },
      });
    },
    async genreationHours(day) {
      let currectDay = moment().day(day).format("YYYY-MM-DD");
      this.loaderHours = true;

      this.getAvailableSlots({
        onData: ({ ok, data }) => {
          if (ok) {
            this.forms.day = moment().day(day);
            this.availibiles.hours.afternoon = [];
            this.availibiles.hours.morning = [];
            data.map((d) => {
              // Afternoon
              if (
                moment(d.formatedHour, "HH:mm").isSameOrAfter(
                  moment("12:00", "HH:mm")
                )
              ) {
                // From 12:00 to max 23:00
                this.availibiles.hours.afternoon.push(d);
              }

              // Morning
              if (
                moment(d.formatedHour, "HH:mm").isBefore(
                  moment("12:00", "HH:mm")
                )
              ) {
                // From 08:00 to max 11:45
                this.availibiles.hours.morning.push(d);
              }
            });

            this.loaderHours = false;
          }
        },
        id: this.doctorId,
        categoryId: this.forms.motif,
        day: currectDay,
      });
    },
    onClickHours(hour) {
      if (!hour.isAvailable) alert("indisponible");
      if (hour.isOld) alert("indisponible");

      this.forms.hour = hour.hourDate;
    },
    async onDone() {
      await this.forms.day.set({
        hour: moment(this.forms.hour).format("HH"),
        minute: moment(this.forms.hour).format("mm"),
      });

      localStorage.setItem(
        "booking_appointment",
        JSON.stringify({ static: this.forms, doctor: this.doctorInfo })
      );

      if (
        this.$auth.isAuthenticated() &&
        this.$auth.getUserInfo().role !== "PATIENT"
      ) {
        return;
      }

      this.$router.push({ name: "bookAppointment" });
    },
  },
};
</script>

<style lang="scss" scoped>
header::v-deep .availabel-hours-scroll-color {
  &::-webkit-scrollbar {
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2e6bff;
    border-radius: 2px;
    border: 1px solid #ffeff0;
  }
}
</style>
